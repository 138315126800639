import { TextProps } from '@chakra-ui/react'
import palette from '../palette'
import { tableTextStyles } from './textStyles/tableTextStyles'

const title = {
  display: 'inline-block',
  lineHeight: '16px',
  marginBottom: '4px',
  fontSize: '13px',
}

export const textStyles: Record<'baseStyle', TextProps> &
  Record<'variants', Record<string, TextProps>> = {
  baseStyle: {
    fontWeight: 400,
    color: palette.text.primary,
    fontFamily: 'Inter',
    letterSpacing: '-0.006em',
    fontSize: '14px',
  },
  variants: {
    'sidebar-footer': {
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 'normal',
      color: palette.midGray[500],
    },
    'running-text': {
      color: palette.midGray[300],
      minHeight: '20px',
      lineHeight: '20px',
    },
    'sidebar-section': {
      color: palette.midGray[500],
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.018em',
    },
    title,
    titleDisabled: {
      ...title,
      color: palette.darkGray[100],
      opacity: 0.4,
    },
    tileTitle: {
      color: palette.midDarkGray,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
    },
    smallerTitle: {
      ...title,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
      color: palette.darkGray[200],
    },
    accordionTileTitle: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      color: palette.midDarkGray,
    },
    error: {
      fontWeight: 600,
      color: palette.text.error,
    },
    ...tableTextStyles,
  },
}
