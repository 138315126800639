import { TextProps } from '@chakra-ui/react'
import palette from '../../palette'

export const tableTextStyles: Record<string, TextProps> = {
  tableTitle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: palette.darkGray[200],
    textTransform: 'capitalize',
  },
  tableHeaderColumn: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: palette.midDarkGray,
    textTransform: 'initial',
  },
  tableRow: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: palette.darkGray[200],
  },
  tableRowSecondary: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    color: palette.midGray[200],
  },
  tableRowInactive: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: palette.midGray[300],
  },
  tablePaginationDescription: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: palette.darkGray[200],
    textAlign: 'center',
    textTransform: 'initial',
  },
  tablePaginationLabel: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: palette.darkGray[200],
    textTransform: 'initial',
  },
}
